.skillsattributes h1 {
  margin-top: 2%;
  margin-bottom: 2%;
}

.Skills-box {
  background-color: rgb(241, 241, 241);
  padding: 10px;
  border-top-right-radius: 50px;
  border-bottom-left-radius: 50px;
}

.Skills-box .fa {
  margin: 0 10px;
  font-size: 20px;
}

.Skills-box span {
  margin: 3px;
  font-size: 20px;
  font-weight: bold;
}
