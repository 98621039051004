.resume {
    background-color: ghostwhite;
    padding-top: 3%;
    padding-bottom: 2%;
    width: 100%;
    margin-bottom: 0px;
  }
  
  .resume h3 {
    margin-bottom: 1%;
  }
  