body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background-color: #fff;
}

/*Resume Section*/

.timeline {
  list-style-type: none;
  position: relative;
}

.timeline:before {
  content: "";
  background: #1ecbe1;
  display: inline-block;
  position: absolute;
  left: 20px;
  width: 3px;
  height: 100%;
  z-index: 1;
}

.timeline li {
  margin-top: 3%;
  margin-bottom: 3%;
  margin-left: 2%;
  background: #fff;
}

.timeline li h4 {
  background-color: #1ecbe1;
  padding: 1.5% 0 1.5% 7%;
  color: #fff;
}

.timeline li h4 span {
  font-weight: bold;
  color: gray;
}

.timeline li:before {
  content: "";
  background: #fff;
  display: inline-block;
  position: absolute;
  border-radius: 50%;
  border: 3px Solid #1ecbe1;
  left: 6px;
  width: 30px;
  height: 30px;
  z-index: 1;
}

.footer {
  background-color: #1ecbe1;
  margin-right: 0 !important;
  margin-left: 0 !important;
  width: 100% !important;
  max-width: 100% !important;
  height: 20%;
  padding: 10px;
  padding-left: 10px;
  margin-top: 0%;
}

.footerborder {
  border: 3px solid White;
  margin-left: 0.5% !important;
  margin-right: 0.5% !important;
  position: relative;
}
.footertext {
  background-color: #1ecbe1;
}

.footersocial {
  background-color: #1ecbe1;
}

.social-icons {
  top: 50%;
  transform: translateY(-50%);
  position: sticky;
  z-index: 1;
  width: 80px;
}

.social-icons ul {
  padding: initial;
}
.social-icons ul li {
  height: 50px;
  width: 50px;
  list-style-type: none;
  padding-left: 12px;
  padding-top: 6px;
  margin-left: 5px;
  margin-top: 5px;
  color: #fff;
  background: #1ecbe1;
}

.social-icons ul li:hover {
  padding-left: 30px;
  width: 100px;
  transition: 1s;
}

.social-icons ul li i {
  padding-top: 3px;
  font-size: 30px;
}
