.contactcont a {
  color: #1ecbe1;
}
.conbody {
  margin: 0;
  padding: 0;
}
.contact {
  padding-top: 50px;
  margin-top: 5%;
  background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.5)),
    url("../../../resources/images/ContactBack.jpg");
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  color: #fff;
  margin-bottom: -2%;
}

.contact p {
  padding-top: 10px;
  font-size: 18px;
}

.contact .row {
  padding: 30px 0;
  font-size: 22px;
}

.contact .col-md-4 .fas,
.far {
  padding: 10px;
  font-size: 40px;
}
