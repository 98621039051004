.about {
  padding-top: 4%;
  margin-bottom: -130px;
}
.aboutwhop {
  padding-bottom: 5%;
}

.about h3 {
  padding-top: 5%;
}
.profile-img {
  height: 300px;
}

.skills-bar p {
  font-weight: 600;
  margin-bottom: 2%;
}

.progress {
  border-radius: 16px !important;
  margin-bottom: 4%;
}

.progress-bar {
  border-radius: 16px !important;
  background: #1ecbe1 !important;
}
